import PopupDialog from "../component/PopupDialog";
import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import LicencePlateAllowedArea from "./LicencePlateAllowedArea";
import LicencePlateBlacklistHistory from "./LicencePlateBlacklistHistory";
import {
    changeLicencePlate,
    deleteLicencePlateFromAll,
    getLicencePlateLastActivity,
    loadLicencePlate,
} from "../httpUtils";
import {Alert} from "@material-ui/lab";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    Box, FormControl, InputAdornment, InputLabel,
    Link,
    makeStyles, MenuItem,
    Paper, Select,
    TextField
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LicencePlateInput from "../component/LicencePlate/LicencePlateInput";
import PhoneNumberInput from "../component/PhoneNumber/PhoneNumberInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LicencePlateEventsHistory from "./LicencePlateEventsHistory";
import ListIcon from '@material-ui/icons/List';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import LicencePlateKeycode from "../Keycode/LicencePlateKeycode";

const useStyles = makeStyles((theme) => ({
    inputForm: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2),
    },
    areaAccessAccordion: {
        marginTop: theme.spacing(1),
        expanded: {
            margin: theme.spacing(1)
        }
    },
    alert: {
        marginTop: theme.spacing(1),
    },
    info: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
    },
    iconAccordion:{
        marginRight: theme.spacing(1),
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
}));

export default function LicencePlateInfo(props){
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const [openDeleteLicencePlate, setOpenDeleteLicencePlate] = useState(false);
    const [isDeletingLicencePlate, setDeletingLicencePlate] = useState(false);

    const [isAllowedAreaProcessing, setAllowedAreaProcessing] = useState(false);

    const [forceReloadLicencePlates, setForceReloadLicencePlates] = useState(false);
    const [saveAllowedArea, setSaveAllowedArea] = useState(false);

    const [licencePlate, setLicencePlate] = useState('');
    const [licencePlateInfo, setLicencePlateInfo] = useState('');
    const [licencePlateOwnerName, setLicencePlateOwnerName] = useState('');
    const [licencePlateOwnerPhone, setLicenceOwnerPhone] = useState('');
    const [licencePlateOwnerEmail, setLicenceOwnerEmail] = useState('');
    const [licencePlateOversizeVehicle, setLicencePlateOversizeVehicle] = useState(false);
    const [licencePlateIgnoreAreaCapacity, setLicencePlateIgnoreAreaCapacity] = useState(false);

    const [licencePlateAllowFrom, setLicencePlateAllowFrom] = useState(undefined);
    const [licencePlateAllowTo, setLicencePlateAllowTo] = useState(undefined);
    const [licencePlateAllowLeaveTo, setLicencePlateAllowLeaveTo] = useState(undefined);
    const [licencePlateOneTimePass, setLicencePlateOneTimePass] = useState(undefined);

    const [changedLicencePlate, setChangedLicencePlate] = useState('');
    const [changedLicencePlateInfo, setChangedLicencePlateInfo] = useState('');
    const [changedLicencePlateOwnerName, setChangedLicencePlateOwnerName] = useState('');
    const [changedLicencePlateOwnerPhone, setChangedLicenceOwnerPhone] = useState('');
    const [changedLicencePlateOwnerEmail, setChangedLicenceOwnerEmail] = useState('');
    const [changedLicencePlateOversizeVehicle, setChangedLicencePlateOversizeVehicle] = useState(false);
    const [changedLicencePlateIgnoreAreaCapacity, setChangedLicencePlateIgnoreAreaCapacity] = useState(false);

    const [changedLicencePlateAllowFrom, setChangedLicencePlateAllowFrom] = useState(undefined);
    const [changedLicencePlateAllowTo, setChangedLicencePlateAllowTo] = useState(undefined);
    const [changedLicencePlateAllowLeaveTo, setChangedLicencePlateAllowLeaveTo] = useState(undefined);
    const [changedLicencePlateOneTimePass, setChangedLicencePlateOneTimePass] = useState(false);

    const [isChanged, setChanged] = useState(false);
    const [isDuplicateLicencePlate, setDuplicateLicencePlate] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const [isHideKeycode, setHideKeycode] = useState(false);
    const [isHideBlacklistHistory, setHideBlacklistHistory] = useState(false);
    const [isHideEventsHistory, setHideEventsHistory] = useState(false);

    const [licencePlateLastActivity, setLicencePlateLastActivity] = useState(undefined);

    const [isUpdateLicencePlateAvailable, setUpdateLicencePlateAvailable] = useState(false);

    useEffect(()=>{
        if (props.isUpdateLicencePlateAvailable){
            setUpdateLicencePlateAvailable(props.isUpdateLicencePlateAvailable)
        }
    }, [props.isUpdateLicencePlateAvailable])

    useEffect(()=>{
        if (
            changedLicencePlate !== licencePlate ||
            licencePlateInfo !== changedLicencePlateInfo ||
            licencePlateOwnerName !== changedLicencePlateOwnerName ||
            licencePlateOwnerPhone !== changedLicencePlateOwnerPhone ||
            licencePlateOwnerEmail !== changedLicencePlateOwnerEmail ||
            licencePlateOversizeVehicle !== changedLicencePlateOversizeVehicle ||
            licencePlateIgnoreAreaCapacity !== changedLicencePlateIgnoreAreaCapacity ||
            licencePlateAllowFrom !== changedLicencePlateAllowFrom ||
            licencePlateAllowTo !== changedLicencePlateAllowTo ||
            licencePlateAllowLeaveTo !== changedLicencePlateAllowLeaveTo ||
            licencePlateOneTimePass !== changedLicencePlateOneTimePass
        ){
            setChanged(true);
        } else {
            setChanged(false);
        }
    }, [changedLicencePlate, changedLicencePlateInfo, changedLicencePlateOwnerName, changedLicencePlateOwnerPhone, changedLicencePlateOwnerEmail, changedLicencePlateOversizeVehicle, changedLicencePlateIgnoreAreaCapacity, changedLicencePlateAllowFrom, changedLicencePlateAllowTo, changedLicencePlateAllowLeaveTo, changedLicencePlateOneTimePass])

    useEffect(()=>{
        setDuplicateLicencePlate(false);
    }, [changedLicencePlate])

    useEffect(()=>{
        if (props.open && props.number){
            setOpen(props.open);
            setLicencePlate(props.number);
            setChangedLicencePlate(props.number);
        }
    }, [props.number, props.open])

    useEffect(()=>{
        if (forceReloadLicencePlates) {
            setForceReloadLicencePlates(false);
            props.hasOwnProperty('onNeedParentReload') && props.onNeedParentReload();
        }
    }, [forceReloadLicencePlates])

    useEffect(()=>{
        if (saveAllowedArea) {
            setSaveAllowedArea(false)
        }
    }, [saveAllowedArea])

    function loadInfo(licencePlateInfo) {
        loadLicencePlate(licencePlateInfo).then(v => {
            console.log(v)

            setLicencePlateInfo(v.info);
            setLicencePlateOwnerName(v.ownerName);
            setLicenceOwnerPhone(v.ownerPhone);
            setLicenceOwnerEmail(v.ownerEmail);
            setLicencePlateOversizeVehicle(v.oversizedVehicle);
            setLicencePlateIgnoreAreaCapacity(v.ignoreAreaCapacity);

            setChangedLicencePlateInfo(v.info);
            setChangedLicencePlateOwnerName(v.ownerName);
            setChangedLicenceOwnerPhone(v.ownerPhone);
            setChangedLicenceOwnerEmail(v.ownerEmail);
            setChangedLicencePlateOversizeVehicle(v.oversizedVehicle);
            setChangedLicencePlateIgnoreAreaCapacity(v.ignoreAreaCapacity);

            if (v.allowFrom) {
                setLicencePlateAllowFrom(new Date(v.allowFrom));
                setChangedLicencePlateAllowFrom(new Date(v.allowFrom));
            }

            if (v.allowTo) {
                setLicencePlateAllowTo(new Date(v.allowTo));
                setChangedLicencePlateAllowTo(new Date(v.allowTo));
            }

            if (v.allowLeaveTo) {
                setLicencePlateAllowLeaveTo(new Date(v.allowLeaveTo));
                setChangedLicencePlateAllowLeaveTo(new Date(v.allowLeaveTo));
            }

            setLicencePlateOneTimePass(v.oneTimePass);
            setChangedLicencePlateOneTimePass(v.oneTimePass);

        }).catch(e => {
            console.error(e)
        })
    }

    function loadLicencePlateLastActivity(_licencePlate) {
        setLicencePlateLastActivity(undefined)
        getLicencePlateLastActivity(_licencePlate).then(v => {
            setLicencePlateLastActivity(v)
        }).catch(e => {
            console.error(e)
        })
    }

    useEffect(()=>{
        if (licencePlate){
            loadInfo(licencePlate)
            loadLicencePlateLastActivity(licencePlate)
        }
    }, [licencePlate]);

    useEffect(()=>{
        if (changedLicencePlateOneTimePass) {
            setChangedLicencePlateAllowLeaveTo(undefined);
        }
    }, [changedLicencePlateOneTimePass]);

    function close() {
        props.onClose();
    }

    function reloadParent(){
        setForceReloadLicencePlates(true);
    }

    function closeDeleteLicencePlate(){
        setOpenDeleteLicencePlate(false);
        setDeletingLicencePlate(false);
    }

    function deleteFromAll() {
        setDeletingLicencePlate(true);
        deleteLicencePlateFromAll(licencePlate).then(()=>{
            setDeletingLicencePlate(false);
            setOpenDeleteLicencePlate(false);
            close();
            reloadParent();
        }).catch((e)=>{
            setDeletingLicencePlate(false);
        })
    }

    function save(){
        setSaving(true);
        changeLicencePlate(licencePlate, changedLicencePlate, changedLicencePlateInfo, changedLicencePlateOwnerName, changedLicencePlateOwnerPhone, changedLicencePlateOwnerEmail, changedLicencePlateOversizeVehicle, changedLicencePlateIgnoreAreaCapacity, changedLicencePlateAllowFrom ? new Date(changedLicencePlateAllowFrom).toUTCString(): undefined, changedLicencePlateAllowTo ? new Date(changedLicencePlateAllowTo).toUTCString(): undefined, changedLicencePlateAllowLeaveTo ? new Date(changedLicencePlateAllowLeaveTo).toUTCString(): undefined, changedLicencePlateOneTimePass || false).then(v=>{
            setSaving(false);
            if (v){
                setLicencePlate(changedLicencePlate);
                loadInfo(changedLicencePlate);
                reloadParent();
            } else {
                setDuplicateLicencePlate(true);
            }
        }).catch(e=>{
            console.error(e)
        })
    }

    return(
        <PopupDialog open={open} onClose={close} title={`Номерной знак: ${licencePlate}`} actionBoard={
            <React.Fragment>
                <Button color="primary" onClick={save} disabled={!isChanged || isSaving || !isUpdateLicencePlateAvailable}>
                    Сохранить
                </Button>
                <Button color="secondary" onClick={()=>{setOpenDeleteLicencePlate(true)}} disabled={isSaving || !isUpdateLicencePlateAvailable}>
                    Удалить
                </Button>
            </React.Fragment>
        }>
            <Grid container spacing={1} className={classes.gridBox}>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <LicencePlateInput
                        disabled={isSaving || !isUpdateLicencePlateAvailable}
                        value={changedLicencePlate}
                        onChange={(e, m)=>{
                            setChangedLicencePlate(e)
                        }}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <TextField
                        disabled={isSaving || !isUpdateLicencePlateAvailable}
                        fullWidth
                        label="Комментарий"
                        variant="outlined"
                        value={changedLicencePlateInfo}
                        onChange={(e)=>{setChangedLicencePlateInfo(e.target.value)}}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <TextField
                        disabled={isSaving || !isUpdateLicencePlateAvailable}
                        fullWidth
                        label="Имя владельца"
                        variant="outlined"
                        value={changedLicencePlateOwnerName}
                        onChange={(e)=>{setChangedLicencePlateOwnerName(e.target.value)}}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <PhoneNumberInput
                        disabled={isSaving || !isUpdateLicencePlateAvailable}
                        label="Телефон владельца"
                        value={changedLicencePlateOwnerPhone}
                        onChange={(e, m)=>{
                            setChangedLicenceOwnerPhone(e)
                        }}
                        withCallActionButton
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <TextField
                        disabled={isSaving || !isUpdateLicencePlateAvailable}
                        fullWidth
                        label="Электронная почта владельца"
                        variant="outlined"
                        type={"email"}
                        value={changedLicencePlateOwnerEmail}
                        onChange={(e)=>{setChangedLicenceOwnerEmail(e.target.value)}}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <FormControlLabel
                        style={{marginBottom: 0}}
                        control={
                            <Checkbox
                                checked={changedLicencePlateOversizeVehicle}
                                onChange={(e)=>{
                                    setChangedLicencePlateOversizeVehicle(e.target.checked)
                                }}
                                color="primary"
                            />
                        }
                        label="Крупногабаритное ТС"
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
                    <FormControlLabel
                        style={{marginBottom: 0}}
                        control={
                            <Checkbox
                                checked={changedLicencePlateIgnoreAreaCapacity}
                                onChange={(e)=>{
                                    setChangedLicencePlateIgnoreAreaCapacity(e.target.checked)
                                }}
                                color="primary"
                            />
                        }
                        label="Приоритетный проезд на участки с ограниченным колличеством мест"
                    />
                </Grid>
            </Grid>

                    {licencePlateLastActivity && licencePlateLastActivity.hasOwnProperty('licencePlate') && licencePlateLastActivity.licencePlate ?
                        <Paper className={classes.info}>
                            <Typography>
                                Локация: {licencePlateLastActivity.hasOwnProperty('lastMovingTo') ?
                                `${licencePlateLastActivity.lastMovingTo || `Улица` } с ${new Date(licencePlateLastActivity.lastActivityTime).toLocaleString()}`
                                : 'Не удалось определить'}
                            </Typography>
                        </Paper>
                    :
                        null
                    }

            <Accordion hidden={isHideEventsHistory} className={classes.areaAccessAccordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Box alignItems="center" display="flex">
                        <Box>
                            <ListIcon className={classes.iconAccordion}/>
                        </Box>
                        <Box>
                            <Typography>История событий</Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <LicencePlateEventsHistory onReload={forceReloadLicencePlates} number={licencePlate} isEmpty={(e)=>{
                        setHideEventsHistory(e);
                    }}/>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.areaAccessAccordion} disabled={isSaving}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Box alignItems="center" display="flex">
                        <Box>
                            <DoneIcon className={classes.iconAccordion}/>
                        </Box>
                        <Box>
                            <Typography>Зоны доступа</Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <LicencePlateAllowedArea onReload={forceReloadLicencePlates} number={licencePlate} onNeedRelaod={reloadParent} onSaveAvailable={(v)=>setAllowedAreaProcessing(!v)} onSave={saveAllowedArea} disabled={isSaving || !isUpdateLicencePlateAvailable}/>
                </AccordionDetails>
                <AccordionActions>
                    <Button size="small" color="primary" disabled={isAllowedAreaProcessing || isSaving || !isUpdateLicencePlateAvailable} onClick={()=>{setSaveAllowedArea(true)}}>
                        Сохранить
                    </Button>
                </AccordionActions>
            </Accordion>
            <Accordion hidden={isHideBlacklistHistory} className={classes.areaAccessAccordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Box alignItems="center" display="flex">
                        <Box>
                            <BlockIcon className={classes.iconAccordion}/>
                        </Box>
                        <Box>
                            <Typography>История блокировок</Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <LicencePlateBlacklistHistory onReload={forceReloadLicencePlates} number={licencePlate} isEmpty={(e)=>{
                        setHideBlacklistHistory(e);
                    }}/>
                </AccordionDetails>
            </Accordion>

            <Accordion className={classes.areaAccessAccordion} disabled={isSaving}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Box alignItems="center" display="flex">
                        <Box>
                            <QueryBuilderIcon className={classes.iconAccordion}/>
                        </Box>
                        <Box>
                            <Typography>Ограничение по времени</Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <Grid container spacing={1}>
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                            <FormControlLabel
                                style={{marginTop: 0}}
                                control={
                                    <Checkbox
                                        checked={changedLicencePlateOneTimePass}
                                        onChange={(e)=>{
                                            setChangedLicencePlateOneTimePass(e.target.checked)
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Единоразовый доступ в течении заданного периода"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                                <DateTimePicker
                                    fullWidth
                                    label="Доступ разрешен с"
                                    inputVariant="outlined"
                                    ampm={false}
                                    value={changedLicencePlateAllowFrom ? changedLicencePlateAllowFrom : null }
                                    onChange={(e)=> setChangedLicencePlateAllowFrom(e ? e.toDate() : undefined)}
                                    disabled={isSaving}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={"end"} variant={'standard'}>
                                                <IconButton onClick={(event)=>{setChangedLicencePlateAllowFrom(undefined)}} edge="end">
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    InputAdornmentProps={{
                                        position: "start"
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                                <DateTimePicker
                                    fullWidth
                                    label="Доступ разрешен до"
                                    inputVariant="outlined"
                                    ampm={false}
                                    value={changedLicencePlateAllowTo ? changedLicencePlateAllowTo : null }
                                    onChange={(e)=> setChangedLicencePlateAllowTo(e ? e.toDate() : undefined)}
                                    disabled={isSaving}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position={"end"} variant={'standard'}>
                                                <IconButton onClick={(event)=>{setChangedLicencePlateAllowTo(undefined)}} edge="end">
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {
                            !changedLicencePlateOneTimePass &&
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("ru")}>
                                    <DateTimePicker
                                        fullWidth
                                        label="Выезд разрешен до"
                                        inputVariant="outlined"
                                        ampm={false}
                                        value={changedLicencePlateAllowLeaveTo ? changedLicencePlateAllowLeaveTo : null }
                                        onChange={(e)=> setChangedLicencePlateAllowLeaveTo(e ? e.toDate() : undefined)}
                                        disabled={isSaving}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position={"end"} variant={'standard'}>
                                                    <IconButton onClick={(event)=>{setChangedLicencePlateAllowLeaveTo(undefined)}} edge="end">
                                                        <CloseIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        }

                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion hidden={isHideKeycode} className={classes.areaAccessAccordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Box alignItems="center" display="flex">
                        <Box>
                            <SettingsRemoteIcon className={classes.iconAccordion}/>
                        </Box>
                        <Box>
                            <Typography>Ключи / Метки</Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {/*<LicencePlateBlacklistHistory onReload={forceReloadLicencePlates} number={licencePlate} isEmpty={(e)=>{*/}
                    {/*    setHideBlacklistHistory(e);*/}
                    {/*}}/>*/}
                    <LicencePlateKeycode licencePlate={licencePlate}/>
                </AccordionDetails>
            </Accordion>

            {
                isDuplicateLicencePlate && <Alert variant="outlined" severity="error" className={classes.alert}>
                    Измененный номерной знак не может быть сохранен. В базе данных уже присутствует номерной знак {changedLicencePlate}.
                </Alert>
            }

            <PopupDialog open={openDeleteLicencePlate} onClose={closeDeleteLicencePlate} title={`Удаление: ${licencePlate}`} actionBoard={
                <React.Fragment>
                    <Button color="default" onClick={closeDeleteLicencePlate}>
                        Отменить
                    </Button>
                    <Button color="secondary" onClick={deleteFromAll} disabled={isDeletingLicencePlate || !isUpdateLicencePlateAvailable}>
                        Удалить
                    </Button>
                </React.Fragment>
            }>
                <Alert severity="error">Это действие безвозвратно удалит: историю блокировок номерного знака, зоны доступа номерного знака, произведет отвязку номерного знака от квартир и парковочных мест. В дальнейшем номер будет считаться неизвестным.<br/>История событий доступа затронута не будет.</Alert>
            </PopupDialog>
        </PopupDialog>

    )
}
