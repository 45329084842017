import {ApartmentCreateError, UnlivedCreateError} from "./errors";
import {getSortParam} from "./utils";

export async function loadBarrierList() {
    let r = await fetch(process.env.REACT_APP_API_URL + '/parking/barrier', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    })
    return await r.json();
}

export async function openBarrier(barrierId, isConcierge, conciergeComment) {
    let r = await fetch(process.env.REACT_APP_API_URL+'/parking/barrier/open', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({id :barrierId, isConcierge, conciergeComment, barrierId}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return await r.json()
}

export async function loadApartment(filter){
    let r = await fetch(process.env.REACT_APP_API_URL+`/apartments/new${filter ? filter: ''}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return await r.json()
}

export async function addApartmentNumber(apartmentNumber){
    let r = await fetch(process.env.REACT_APP_API_URL+'/apartments/new', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ apartmentNumber }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status !== 200){
        throw new ApartmentCreateError(r.status, (await r.json()).apartmentId);
    }
    return await r.json()
}

export async function loadLicencePlates(filter){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/new${filter ? filter: ''}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return (await r.json())
}

export async function loadArea(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/area`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return (await r.json()).area
}

export async function loadLicencePlateArea(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/area/`+licencePlate,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return (await r.json())
}

export async function setLicencePlateArea(licencePlate, allowedArea){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/area/`+licencePlate,{
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ allowedArea }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return (await r.json())
}

export async function loadBlacklistHistory(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/blacklist/`+licencePlate,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return (await r.json())
}

export async function loadLicencePlateShortInfo(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/shortinfo/`+licencePlate,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return (await r.json())
}

export async function loadLicencePlate(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/`+licencePlate,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return (await r.json())
}

export async function changeLicencePlate(currentLicencePlate, licencePlate, licencePlateInfo, licencePlateOwnerName, licencePlateOwnerPhone, licencePlateOwnerEmail, oversizedVehicle, ignoreAreaCapacity, licencePlateAllowFrom, licencePlateAllowTo, licencePlateAllowLeaveTo, licencePlateOneTimePass){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/${currentLicencePlate}`,{
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({licencePlate, licencePlateInfo, licencePlateOwnerName, licencePlateOwnerPhone, licencePlateOwnerEmail, oversizedVehicle, ignoreAreaCapacity, licencePlateAllowFrom, licencePlateAllowTo, licencePlateAllowLeaveTo, licencePlateOneTimePass}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 409: return false;
    }
}

export async function loadLicencePlateAreaInfo(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/areainfo/`+licencePlate,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 404: throw new Error(404);
    }
}

export async function changeLicencePlateArea(licencePlate, disallowReason, disallowAreas){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/area/${licencePlate}`,{
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ disallowReason, disallowAreas }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    if (r.status === 200){
        return true;
    }
}

export async function deleteLicencePlateFromBlacklist(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/blacklist/`+licencePlate,{
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    if (r.status === 200){
        return true;
    }
}

export async function deleteLicencePlateFromAll(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/`+licencePlate,{
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    if (r.status === 200){
        return true;
    }
}

export async function isLicencePlateKnown(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/check/${licencePlate}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 404: return false;
    }
}

export async function createLicencePlateWithInfo(licencePlate, licencePlateInfo, licencePlateOwnerName, licencePlateOwnerPhone, licencePlateOwnerEmail, oversizedVehicle){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/`,{
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ licencePlate, licencePlateInfo, licencePlateOwnerName, licencePlateOwnerPhone, licencePlateOwnerEmail, oversizedVehicle}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json()).licencePlate;
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function findLinkedApartmentLicencePlates(apartmentId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/apartments/numbers/${apartmentId}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function unbindApartmentLicencePlates(apartmentId, licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/apartments/numbers/${apartmentId}/${licencePlate}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function bindApartmentLicencePlates(apartmentId, licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/apartments/numbers/${apartmentId}`, {
        method: 'POST',
        body: JSON.stringify({number : licencePlate }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function findCurrentStatements(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/statements`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function findStatement(statementUUID){
    let r = await fetch(process.env.REACT_APP_API_URL+`/statements/${statementUUID}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function bindLicencePlatesToApartment(apartmentNumber, licencePlates){
    let r = await fetch(process.env.REACT_APP_API_URL+`/apartments/bindLicencePlates/${apartmentNumber}`, {
        method: 'POST',
        body: JSON.stringify({ licencePlates }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function sendLogin(userLogin, userPassword){
    let r = await fetch(process.env.REACT_APP_API_URL+`/admin/login`, {
        method: 'POST',
        body: JSON.stringify({ login: userLogin, password: userPassword }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function sendLogout(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/admin/logout`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getFeatures(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/admin/check`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadCustomerList(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/admin/customers`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}


export async function changeLicencePlateStatementStatus(licencePlate, statementUUID, status, disallowArea){
    let r = await fetch(process.env.REACT_APP_API_URL+`/statements/licencePlates/${statementUUID}/${licencePlate}/${status}`,{
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({disallowArea}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 400: return false;
    }
}

export async function changeStatementStatus(statementUUID, status){
    let r = await fetch(process.env.REACT_APP_API_URL+`/statements/status/${statementUUID}/${status}`,{
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 400: return false;
    }
}

export async function getLicencePlateStatistics(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/overview/licencePlates`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getApartmentStatistics(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/overview/apartments`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getLicencePlateLastActivity(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/lastactivity/${licencePlate}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getLicencePlateLastHistory(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/lasthistory/${licencePlate}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getLogs(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/admin/log`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getCalls(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/call/history`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getReportLpwob(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/reports/lpwob`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getReportQuotas(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/reports/quotas`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getReportLicencePlatesWithKeycodes(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/reports/lpwkc`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getLicencePlateActivity(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/events/activity`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getUICreateLicencePlateDisallowArea(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/ui/default/disallowArea`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json()).disallowArea;
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadCamerasList(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/camera`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json()).camera;
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadCamerasNew(cameraId=undefined){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/camera/new${cameraId?`/${cameraId}`:``}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function requestCameraRealtimeImage(cameraId){
    let r1 = await fetch(process.env.REACT_APP_API_URL+`/parking/camera/realtime/${cameraId}`,{
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
    })
}

export async function getCameraRealtimeImage(cameraId){
    // Convert array buffer to base64
    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.length; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    };

    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/camera/realtime/${cameraId}`,{
        method: 'GET',
        credentials: 'include',

    })

    switch (r.status){
        case 200:
            const base64Image = arrayBufferToBase64(await r.arrayBuffer());
            return `data:image/png;base64,${base64Image}`
        case 401: throw new Error(401);
        case 403: throw new Error(403);
        case 404: throw new Error(404);
    }
}

export async function getCameraAreaMapping(cameraId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/camera/area/${cameraId}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return (await r.json()).area
    switch (r.status){
        case 200: return (await r.json()).area;
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadBarrierExtendedData(){
    let r = await fetch(process.env.REACT_APP_API_URL+'/parking/barrier/extended',{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json()).barrier;
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function getMenuParkings(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/admin/parking`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function setMenuParking(currentSelectedParkingId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/admin/parking`,{
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({currentSelectedParkingId}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 403: return false;
    }
}

export async function loadAreasList(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/area`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json()).area;
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadAvailableAreasList(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/area/new`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadAreaDetail(areaId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/area/${areaId}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json()).area;
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function changeAreaDetail(areaId, areaName, areaGuest, areaCapacity){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/area/${areaId}`,{
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({name: areaName, guest: areaGuest, capacity: areaCapacity}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 409: return false;
    }
}

export async function loadUnlived(filter){
    let r = await fetch(process.env.REACT_APP_API_URL+`/unlived${filter ? filter: ''}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return await r.json()
}

export async function loadUnlivedPrefixes(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/unlived/prefixes/dictionary`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status === 401){
        throw new Error(401);
    }
    return await r.json()
}

export async function findLinkedUnlivedLicencePlates(unlivedId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/unlived/linkedLicencePlates/${unlivedId}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function bindLicencePlatesToUnlived(createObjectType, createObjectArea, createObjectNumber, createObjectChar, oneTimeLicencePlatesLimit, licencePlates){
    let r = await fetch(process.env.REACT_APP_API_URL+'/unlived/bindLicencePlates', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ createObjectType, createObjectArea, createObjectNumber: parseInt(createObjectNumber), createObjectChar, oneTimeLicencePlatesLimit, licencePlates }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status !== 200){
        throw new UnlivedCreateError(r.status, (await r.json()).unlivedId);
    }
    return true
}

export async function createUnlived(createObjectType, createObjectArea, createObjectNumber, createObjectChar, oneTimeLicencePlatesLimit){
    let r = await fetch(process.env.REACT_APP_API_URL+'/unlived', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ createObjectType, createObjectArea, createObjectNumber: parseInt(createObjectNumber), createObjectChar, oneTimeLicencePlatesLimit }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status !== 201){
        throw new UnlivedCreateError(r.status, (await r.json()).unlivedId);
    }
    return await r.json()
}

export async function unbindLicencePlateFromUnlived(unlivedId, licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/unlived/linkedLicencePlates/${unlivedId}/${licencePlate}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return true;
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function addLicencePlateToUnlived(unlivedId, licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/unlived/linkedLicencePlates/${unlivedId}`, {
        method: 'POST',
        body: JSON.stringify({ licencePlate }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 201: return true;
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function loadUnlivedInfo(unlivedId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/unlived/${unlivedId}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function updateUnlived(unlivedId, updateObjectType, updateObjectArea, updateObjectNumber, updateObjectChar, updateOneTimeLicencePlatesLimit){
    let r = await fetch(process.env.REACT_APP_API_URL+`/unlived/${unlivedId}`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ unlivedId, updateObjectType, updateObjectArea, updateObjectNumber: parseInt(updateObjectNumber), updateObjectChar, updateOneTimeLicencePlatesLimit }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    if (r.status !== 200){
        throw new UnlivedCreateError(r.status, (await r.json()).unlivedId);
    }
    return await r.json()
}

export async function getGuestJournal(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/guest/journal`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}


// rent section
export async function loadRentActualTariffs(tariffObject){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/calculator/services`+ `${tariffObject ? `?tariffObject=${tariffObject}`: ``}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentContractsWithBills(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/contractsWithBills` + `${licencePlate ? `?licencePlate=${licencePlate}`: ``}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentContracts(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/contracts` + `${licencePlate ? `?licencePlate=${licencePlate}`: ``}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadLastEntryLicencePlate(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/events/lastEntry/${licencePlate}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
        case 404: throw new Error(404);
    }
}

export async function loadRentTariffObjects(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/calculator/tariff/objects`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function createRentContract(payload){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/contract`, {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 201: return true;
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function loadRentContractExtendedInfo(contractId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/contract/extended/${contractId}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentContractBillItemsUponRequest(contractId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/contract/bill/items/uponRequest/${contractId}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentAccountantReportSalesServicesGrouped(begin, end){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/report/accountant/servicesPeerRange?begin=${begin}&end=${end}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentAccountantReportContractsGrouped(begin, end){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/report/accountant/contractsPeerRange?begin=${begin}&end=${end}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentAccountantReportCashBoxRange(begin, end){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/report/accountant/cashBoxRange?begin=${begin}&end=${end}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentAccountantReportServicesRealizedRange(begin, end){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/report/accountant/servicesRealizedRange?begin=${begin}&end=${end}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentServices(){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/services`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadRentService(serviceId){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/service/${serviceId}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function updateService(serviceId, payload){
    let r = await fetch(process.env.REACT_APP_API_URL+`/rent/service/${serviceId}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}
// rent section

// keytags

export async function loadKeytags(filter){
    let r = await fetch(process.env.REACT_APP_API_URL+`/keytag${getSortParam(filter)}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function loadLicencePlateKeytags(licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/parking/numbers/keycodes/${licencePlate}`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
    }
}

export async function isExistKeycode(keycode){
    let r = await fetch(process.env.REACT_APP_API_URL+`/keytag/check/${ keycode }`,{
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 404: throw new Error(404);
        case 409: throw new Error(409);
    }
}

export async function createKeycode(keycode){
    let r = await fetch(process.env.REACT_APP_API_URL+`/keytag`, {
        method: 'POST',
        body: JSON.stringify({ keycode }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}

export async function bindKeycodeToLicencePlate(keycode, licencePlate){
    let r = await fetch(process.env.REACT_APP_API_URL+`/keytag/link`, {
        method: 'POST',
        body: JSON.stringify({ keycode, licencePlate }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 201: return true;
        case 401: throw new Error(401);
        case 409: throw new Error(409);
    }
}
// keytags

export async function sendPUT(url, body) {
    return send('PUT', url, body)
}

export async function sendPOST(url, body) {
    return send('POST', url, body)
}

export async function sendGET(url, body) {
    return send('GET', url, body)
}

export async function send(method, url, body) {
    let r = await fetch(url, {
        method: method,
        body: (body !== undefined && method !== "GET") ? JSON.stringify(body): undefined,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    switch (r.status){
        case 200: return (await r.json());
        case 201: return (await r.json());
        case 401: throw new Error(401);
        case 403: throw new Error(403);
        case 409: throw new Error(409);
    }
}

export async function updateCameraConfig(payload){
    return sendPUT(process.env.REACT_APP_API_URL+`/parking/camera/new`, payload)
}

export async function getCustomerPermissionList(customerId){
    return sendGET(process.env.REACT_APP_API_URL+`/admin/functions/customer/${customerId}`)
}

export async function getSystemPermissionList(){
    return sendGET(process.env.REACT_APP_API_URL+`/admin/functions/functions`)
}

export async function setCustomerPermissionList(customerId, permissions){
    return sendPUT(process.env.REACT_APP_API_URL+`/admin/functions/customer/${customerId}`, permissions)
}

export async function getCustomerParkingList(customerId){
    return sendGET(process.env.REACT_APP_API_URL+`/admin/parking/customer/${customerId}`)
}

export async function getSystemParkingList(){
    return sendGET(process.env.REACT_APP_API_URL+`/admin/parking/parking`)
}

export async function setCustomerParkingList(customerId, parkings){
    return sendPUT(process.env.REACT_APP_API_URL+`/admin/parking/customer/${customerId}`, parkings)
}

export async function createCustomer(login, password){
    return sendPUT(process.env.REACT_APP_API_URL+`/admin/user/new`, {login, password})
}